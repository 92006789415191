import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.png";
import Particle from "../Particle";
import Home2 from "./Home2";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row
            style={{
              display: "flex",
              flexWrap: "wrap-reverse",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col md={7} className="home-header" style={{ textAlign: "center" }}>
              <p className="heading">از تصویر ذهنی تا کد واقعی </p>
              <p className="heading-name lh-lg">
                سفری جذاب به دنیای برنامه نویسی همراه با{" "}
                <h1 className="main-name mt-3">تیم آکو</h1>
              </p>

              <div
                style={{
                  padding: 50,
                  textAlign: "center",
                  marginRight: "200",
                }}
              ></div>
            </Col>

            <Col md={5} style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={homeLogo}
                alt="تیم آکو"
                className="img-fluid img-top-header"
                style={{ borderRadius: "50px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
