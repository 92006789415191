import React from "react";
import Card from "react-bootstrap/Card";
function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <strong
            style={{
              textAlign: "justify",
              fontSize: "1.2em",
              lineHeight: "55px",
            }}
          >
            تیم آکو، یک جامعه از برنامه‌نویسان و آفرینندگان است که در دنیای
            فناوری مشغول به کار هستند. هدف ما از تلاش و توانایی‌های فنی ما فراتر
            می‌رود. ما به دنبال ایجاد نرم‌افزارهایی هستیم که نه تنها مسائل
            روزمره را حل می‌کنند، بلکه به جامعه و جهان ارزش افزوده می‌دهند. از
            رمزگشایی مسائل پیچیده گرفته تا ساختن ارتباطات کاربری ساده و جذاب،
            همه در تیم آکو به دنبال تغییر و ایجاد نوآوری‌هایی هستیم که مرزهای
            فناوری را گسترش داده و دنیایی جدید از امکانات را بوجود آورده‌اند.
            اینجا جایی است که اراده، هوش و خلاقیت به یکدیگر می‌پیوندد تا
            آینده‌ای با ابعادی بی‌نظیر بسازیم.
          </strong>
          <br />
          <br />
          <br />
          <h2> استعداد علاقه ی دنبال شده است</h2>
          <br />
          <br />
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
