import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Cardskil from "./Cardskil";
import { Link } from "react-router-dom";

function Register() {
  return (
    <Container
      fluid
      className="about-section"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Particle />

      {/* _______________________________ */}
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <br />
        <Col>
          {/* _______________________________ */}
          <Card
            style={{
              color: "#fff",
              backgroundColor: "#171034",
              borderRadius: "20px",
              padding: "20px",
              opacity: ".9",
            }}
          >
            <Card.Header style={{ fontSize: "2.5rem" }}>
              تو این دوره چیا یاد میگیریم ؟{" "}
            </Card.Header>
            <br />
            <Cardskil />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p
                style={{
                  border: "1px solid",
                  display: "inline-block",
                  width: "200px",
                  textAlign: "center",
                  padding: "8px",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
              >
                <Link
                  as={Link}
                  to="/information"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  اطلاعات بیشتر
                </Link>
              </p>
            </div>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                  }}
                ></div>
                <strong style={{ fontSize: "3rem" }}>
                  چرا <span className="main-name">آکو</span> ؟
                </strong>
                <br />
                <br />
              </blockquote>
              <Accordion style={{ color: "black" }}>
                <Accordion.Item
                  eventKey="0"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <Accordion.Header>مزیت اول</Accordion.Header>
                  <Accordion.Body>
                    بعد از هرجلسه تکالیفی برای مرور و یادگیری بهتر درس همان روز
                    به شما داده می شود
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="1"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <Accordion.Header>مزیت دوم</Accordion.Header>
                  <Accordion.Body>
                    مباحث تدریس شده بعد از اتمام جلسه به صورت پی دی اف در اختیار
                    شما دوستان قرار خواهد گرفت
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="2"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <Accordion.Header>مزیت سوم</Accordion.Header>
                  <Accordion.Body>
                    کدهای جلسه در اختیار شما قرار خواهد گرفت
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="3"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <Accordion.Header>مزیت چهارم</Accordion.Header>
                  <Accordion.Body>
                    درصورت مشکل داشتن در مباحث فراگیری شده جلسات رفع اشکال برای
                    شما برگزار خواهد شد
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <br />
              <blockquote className="blockquote mb-0">
                <strong>دوره سوم بزودی شروع میشود</strong>
                <br />
                <br />
                <br />

                <p>جهت ثبت نام فرم زیر را با دقت پر کنید</p>
              </blockquote>
              <br />
            </Card.Body>
            <iframe
              title="form"
              src="https://docs.google.com/forms/d/e/1FAIpQLScRCe4ot_ooMXHVltTmxnb04tUJ2BSoEVgEKlJiC9SVB8z3qQ/viewform?embedded=true"
              height="4003"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src="/img-team-fa.png"
                alt="تیم برنامه نویسی آکو"
                style={{ height: "300px", width: "300px" }}
              />
            </div>
          </Card>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
          ></div>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
