import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { BsTelegram } from "react-icons/bs";
function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <br />
        <Row
          style={{
            display: "flex",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
          }}
        >
          <Col md={4} className="myAvtar">
            <Tilt>
              <img
                src={"/logo.png"}
                className="img-fluid"
                alt="لوگو تیم برنامه نویسی آکو"
              />
            </Tilt>
          </Col>
          <Col
            md={8}
            className="home-about-description"
            style={{ fontSize: "1.5em" }}
          >
            <h5 style={{ fontSize: "2em" }}>
              <span className="purple">آکو</span> به معنای اهداف بزرگ است
            </h5>
            <p
              className="home-about-body lh-lg"
              style={{ textAlign: "center" }}
            >
              جایی که ایده‌های شما به زندگی می‌پیوندند. با تیم آکو داستان شما از
              کدنویسی آغاز می‌شود.
              <br />
              <br />
              .تلاش ما، همواره جهت خلق جامعه‌ای از دیدگاه‌های متنوع و ذهنیت‌های
              خارق‌العاده است
              <br />
              <strong className="lh-lg">
                {" "}
                در هر خط کد, جادویی از هنر و توسعه را تجربه کنید
              </strong>
              <br />
              <h2 className="purple d-inline-block">تیم برنامه نویسی آکو</h2>
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>ارتباط با ما</h1>
            <p style={{ fontSize: "2em" }}>
              email : <span className="purple">info@ako-team.ir</span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/ako-team"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/ako_team_official"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <BsTelegram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/ako_team_official/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
