import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import {
  DiJavascript1,
  DiCss3,
  DiGit,
  DiHtml5,
  DiGithubBadge,
  DiBootstrap,
  DiGithub,
  DiJavascript,
} from "react-icons/di";
export default function Information() {
  return (
    <div>
      <Container
        fluid
        className="about-section"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Particle />
        <Container>
          <h2>برنامه نویسی چیست؟</h2>
          <p className="programming-paragraph">
            برنامه نویسی یعنی نوشتن دستوراتی به زبان کامپیوتر تا کامپیوتر بتواند
            وظایف مختلف را انجام دهد. این دستورات به زبانی خاص نوشته می‌شوند که
            کامپیوتر می‌تواند درک کند. هر زبان برنامه نویسی دارای قوانین و
            ساختارهای خاصی است که برنامه نویس باید آنها را دنبال کند. برنامه
            نویسی می‌تواند به شما کمک کند تا مسائل مختلف را با استفاده از
            کامپیوتر حل کنید. این مسائل می‌توانند از جمله محاسبات علمی، مدیریت
            داده‌ها، طراحی وبسایت‌ها، یا حتی ساخت بازی‌های کامپیوتری باشند.
            برنامه نویسی مانند نوشتن یک داستان است، اما داستان به زبان انسان و
            برنامه نویسی به زبان کامپیوتر انجام می‌شود. به عبارت دیگر، برنامه
            نویسی به شما این امکان را می‌دهد که با کامپیوتر در زبانی که او
            می‌فهمد، ارتباط برقرار کنید و وظایف مختلف را به او بسپارید
          </p>
          <hr />
          <h2>برنامه نویس کیه؟</h2>
          <p className="programming-paragraph">
            {" "}
            برنامه‌نویس، کسیه که با کامپیوتر حرف می‌زنه و بهش میگه چیکار کنه.
            مثل یک معلم که به دانش‌آموزش میگه چطور مسئله‌ها رو حل کنه. مهمترین
            کار برنامه‌نویس اینه که به کامپیوتر بگه چیکار کنه و برنامه براش
            بنویسه. برنامه‌نویسا میتونن در زمینه‌های مختلفی کار کنن، مثل ساخت
            وبسایت، ساخت بازی کامپیوتری، یا حتی درست کردن برنامه‌های خاص برای
            یادگیری ماشین و هوش مصنوعی. هر کدوم از این برنامه‌نویسا توی کارهای
            مختلف مهارت دارن و به جایهای مختلفی می‌تونند کار کنن.
          </p>
          <hr />
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-center justify-content-md-between"
            >
              <DiHtml5 className="icon-information-skill" />
            </Col>
            <Col xs="12" md="6">
              <p className="programming-paragraph" dir="rtl">
                HTML مثل زبانیه که به کامپیوتر می‌گه ساختار یک صفحه وب چطوری
                باشه. به عبارت ساده‌تر، مثل ساخت یک قالب برای صفحه وبتونه. از
                HTML برای ایجاد عناصر مانند عناوین، پاراگراف‌ها، تصاویر و
                لینک‌ها استفاده می‌شه.
              </p>
            </Col>
          </Row>
          <hr />
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-center justify-content-md-between"
            >
              <DiCss3 className="icon-information-skill" />
            </Col>
            <Col xs="12" md="6">
              <p className="programming-paragraph" dir="rtl">
                CSS مثل آرایشگر برای صفحه وب است. با استفاده از CSS، می‌توانید
                رنگ‌ها، فونت‌ها، فواصل و سبک ظاهری دیگر صفحه وبتان را تغییر دهید
                و آن را به شکل زیباتری نمایش دهید.
              </p>
            </Col>
          </Row>
          <hr />
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-center justify-content-md-between"
            >
              <DiGit className="icon-information-skill" />
            </Col>
            <Col xs="12" md="6">
              <p className="programming-paragraph" dir="rtl">
                Git یک ابزار مدیریت نسخه است. یعنی می‌توانید تغییراتی که در
                کدهایتان اعمال می‌کنید را ثبت کنید و در صورت نیاز به وضعیت‌های
                گذشته باز گردید.
              </p>
            </Col>
          </Row>
          <hr />
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-center justify-content-md-between"
            >
              <DiGithub className="icon-information-skill" />
            </Col>
            <Col xs="12" md="6">
              <p className="programming-paragraph" dir="rtl">
                GitHub یک سرویس آنلاین است که به شما این امکان را می‌دهد تا
                کدهایتان را در اینترنت ذخیره کنید و با دیگران به اشتراک بگذارید.
              </p>
            </Col>
          </Row>
          <hr />
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-center justify-content-md-between"
            >
              <DiBootstrap className="icon-information-skill" />
            </Col>
            <Col xs="12" md="6">
              <p className="programming-paragraph" dir="rtl">
                Bootstrap یک کیت ابزاره که برای طراحی وبسایت‌های زیبا و سریع
                استفاده میشه. فکرش رو بکنید مثل یک جعبه با ابزارهای آماده که
                می‌تونید ازشون استفاده کنید. با Bootstrap، می‌تونید به سرعت
                وبسایت‌های زیبا بسازید
              </p>
            </Col>
          </Row>
          <hr />
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              xs="12"
              md="6"
              className="d-flex justify-content-center justify-content-md-between"
            >
              <DiJavascript1 className="icon-information-skill" />
            </Col>
            <Col xs="12" md="6">
              <p className="programming-paragraph" dir="rtl">
                JavaScript مثل مغز صفحه وبه. با این زبان می‌توانید صفحات وب خود
                را پویا کنید، به کاربران امکان تعامل بیشتری بدهید و صفحه را بدون
                نیاز به بارگذاری مجدد به‌روز کنید. به عبارت دیگر، این زبان برای
                ایجاد اثرات جالب و تغییرات زمان واقعی روی صفحه استفاده می‌شود.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
