import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiCss3,
  DiGit,
  DiHtml5,
  DiGithubBadge,
  DiBootstrap,
} from "react-icons/di";
function Cardskil() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <p style={{ fontSize: "15px" }}>HTML</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 />
        <p style={{ fontSize: "15px" }}>CSS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiBootstrap />
        <p style={{ fontSize: "15px" }}>BOOTSTRAP</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p style={{ fontSize: "15px" }}>JAVASCRIPT</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p style={{ fontSize: "15px" }}>GIT</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGithubBadge />
        <p style={{ fontSize: "15px" }}>GITHUB</p>
      </Col>
    </Row>
  );
}

export default Cardskil;
